import Modal from 'react-bootstrap/Modal';
import { ReviewModalProps } from './types';
import ImageGallery from "react-image-gallery";
import ReactStars from 'react-stars';
import "./styles.scss";
import { useEffect, useState } from 'react';

const ReviewModal = (props: ReviewModalProps) => {

    const { show, hideModal, image, rating, name, postedDate,
        comment, reviewImages } = props;

    const [images, setImages] = useState<{ original: string, thumbnail: string }[]>([]);

    useEffect(() => {
        if (reviewImages && reviewImages.length > 0) {
            const images = reviewImages.map(image => {
                return {
                    original: image,
                    thumbnail: image
                }
            });

            setImages(images);
        }
    }, [reviewImages]);


    return (
        <Modal
            size="lg"
            show={show}
            onHide={() => hideModal()}
            aria-labelledby="review-item-modal">
            <Modal.Header closeButton>
                <Modal.Title />
            </Modal.Header>

            <Modal.Body>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12 col-sm-12 col-md-12'>
                            {images && images.length > 0 && <div className='image-box'>
                                <ImageGallery items={images} />
                            </div>}
                        </div>
                        <div className='col-12 col-sm-12 col-md-12'>
                            <div className='review-popup-content'>
                                <div className='header'>
                                    <div className="user-image">
                                        {image}
                                    </div>

                                    <div className="top-content">
                                        <h5 className="user-name">{name}</h5>
                                        <div className="posted-time"> {postedDate} </div>
                                    </div>
                                </div>

                                <div className="rating">
                                    <ReactStars
                                        count={5}
                                        size={20}
                                        edit={false}
                                        value={rating}
                                        color2={'#ffd700'} />
                                </div>
                                <div className='comment'>
                                    {comment}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ReviewModal;